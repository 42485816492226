import React from 'react'
import './App.css';
import Title from './components/Title';
import Main from './components/Main';
import Breakdown from './components/Breakdown';
import Categories from './components/Categories';
import { Rates } from './components/Rates';

function App() {
  return (
    <div className="App">
      <Title />
      <Rates />
      <Categories />
      <Main />
      <Breakdown />
    </div>
  );
}

export default App;
